import React, { useEffect, useState } from "react";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import axios from "axios";
import { BaseUrl, token } from "../../utils/Token";
import Loader from "../../common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ImageComponent from "../../components/ImageComponent";

function Brand() {
  const [name, setName] = useState("");
  const { id } = useParams()
  const [flow, setFlow] = useState("");
  const [file, setFile] = useState(null);
  const [is_trademark, setis_trademark] = useState(false)
  const [trademarkfile, settrademarkfile] = useState("")

  const [flowApi, setFlowApi] = useState([]);
  const [open, setOpen] = useState(false);

  const [Idenititycert, setIdenitityCert] = useState(null)

  const [editidentity, seteditidentity] = useState(false)


  const getBrandbyId = () => {
    axios
      .get(`${BaseUrl}/single_brand/${id}`, { headers })
      .then((res) => {


        setFlow(res.data.data[0].flow._id);
        setName(res.data.data[0].name);
        setis_trademark(res.data.data[0].is_trademark)

        if (res.data.data[0].business_document !== "") {
          seteditidentity(true)
        }
        settrademarkfile(res.data.data[0].trademark)

        setFile(res.data.data[0].image);

      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (id) {
      getBrandbyId()
    }
  }, [id])


  const [enlargedSrc, setEnlargedSrc] = useState(null);

  const closeEnlargedView = () => {
    setEnlargedSrc(null);
  };


  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const naivgate = useNavigate();




  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);

    const formData = new FormData();
    formData.append("name", name);

    formData.append("flow", flow);

    formData.append("file", file);
    formData.append("business_documents", Idenititycert)

    formData.append("is_trademark", is_trademark)
    if (id) {
      formData.append("_id", id);
    }

    if (!is_trademark || is_trademark == "true" || is_trademark) {
      if (id) {
        axios
          .put(`${BaseUrl}/brands`, formData, { headers })
          .then((response) => {
            // Handle successful response
            console.log(response.data);
            if (response.data.status == "OK") {
              toast.success("Data Submited Successfully");
              setOpen(false);
              naivgate("/brandlist");
            }
          })
          .catch((error) => {

            console.error(error);
            toast.error("Data not Submitted");
            setOpen(false);
          });
      } else {
        axios
          .post(`${BaseUrl}/brands`, formData, { headers })
          .then((response) => {
            // Handle successful response
            console.log(response.data);
            if (response.data.status == "OK") {
              toast.success("Data Submited Successfully");
              setOpen(false);
              naivgate("/brandlist");
            }
          })
          .catch((error) => {

            console.error(error);
            toast.error("Data not Submitted");
            setOpen(false);
          });
      }
    } else {
      toast.error("If You Dont Have Trademark then you dont Upload Brand")
      setOpen(false)
    }
  };

  const FlowgetApi = () => {
    axios.get(`${BaseUrl}/flows`, { headers }).then((res) => {
      setFlowApi(res.data.data);
    });
    console.log(flowApi);
    // console.log(Brand[3].parent_Brand.name);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  useEffect(() => {
    FlowgetApi();
  }, []);

  // const handletrademark = (e) => {
  //   let selectedfile = e.target.files[0]
  //   settrademarkfile(selectedfile)
  // }





  const getFileUrl = () => {
    if (editidentity) {
      return `${BaseUrl}/${Idenititycert}`;
    } else if (Idenititycert instanceof Blob) {
      return URL.createObjectURL(new Blob([Idenititycert]));
    } else if (typeof Idenititycert === 'string') {
      return Idenititycert;
    }
    return '';
  };


  const fileUrl = getFileUrl();
  const isPdf = fileUrl && fileUrl.endsWith('.pdf');
  const handlePdfClick = () => {
    window.open(fileUrl);
  };

  const handleImageClick = (src) => {
    setEnlargedSrc(src);
  };

  const handleidenitity = (event) => {
    const uploadaadharFront = event.target.files[0];
    // Store the uploaded file directly
    setIdenitityCert(uploadaadharFront);
    seteditidentity(false)
  };

  return (
    <>
      <section>
        <div className=""></div>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white box">
                <h4 className="px-3 py-3"> Add Brand</h4>
                <div className="">
                  <Form onSubmit={handleSubmit}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Name
                          </label>
                          <input
                            type="text"
                            required
                            name="name"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Flow
                          </label>

                          <select
                            className="form-select"
                            name="flow"
                            aria-label="Default select example"
                            value={flow}
                            onChange={(e) => setFlow(e.target.value)}
                          >
                            <option selected>Select a Flow</option>
                            {flowApi.map((item) => {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="d-flex w-100 gap-3 align-items-center">

                          <div className="w-100">
                            <label htmlFor="" className="fw-bold">
                              Image
                            </label>
                            <input
                              type="file"
                              name="file"
                              className="form-control"
                              onChange={handleFileChange}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md='6'>
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">You Have Trademark ? </label>
                          <select
                            name=""
                            value={is_trademark}
                            onChange={(e) => setis_trademark(e.target.value)}
                            className="form-control form-select"
                            id="">
                            <option value="">--Select--</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </FormGroup>
                      </Col>
                      {(is_trademark == "true" || is_trademark) && (
                        <Col md="6">
                          <FormGroup className="d-flex w-100 gap-3 align-items-center">
                            <div className="w-100">
                              <label htmlFor="" className="fw-bold">Trademark Certificate</label>
                              <input
                                type="file"
                                name="file"
                                className="form-control w-100"
                                onChange={handleidenitity}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      )}
                      {/* <Col md="6">
                        <FormGroup className="d-flex w-100 gap-3 align-items-center">
                          {Idenititycert && (
                            isPdf ? (
                              <div onClick={handlePdfClick} className="mt-4" style={{ cursor: 'pointer' }}>
                                <i class="fa-solid fa-file fs-1 text-danger"></i>
                              </div>
                            ) : (
                              <ImageComponent
                                type="file"
                                enlargedSrc={enlargedSrc}
                                closeEnlargedView={closeEnlargedView}
                                handleImageClick={handleImageClick}
                                src={URL.createObjectURL(new Blob([Idenititycert]))}
                              />
                            )
                          )}
                          <div className="w-100">
                            <label htmlFor="" className="fw-bold">Bussiness Document</label>
                            <input
                              type="file"
                              name="file"
                              className="form-control w-100"
                              onChange={handleidenitity}
                            />
                          </div>
                        </FormGroup>
                      </Col> */}
                      {id && <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-3">
                            <label htmlFor="">Trademark</label>
                            <div className="">
                              <img src={`${BaseUrl}/${trademarkfile}`} style={{ width: "200px", height: "200px" }} alt="" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="">Image</label>
                            <div className="">
                              <img src={`${BaseUrl}/${file}`} style={{ width: "200px", height: "200px" }} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>}
                      <Col md="12">
                        <div className="">
                          <button className="btn bg-primary text-white">
                            {id ? "Edit" : "Add"} Brand
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Loader open={open} />
      </section>
    </>
  );
}

export default adminLayout(Brand);
