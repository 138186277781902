

// import React, { useState, useEffect } from "react";
// import PerfectScrollbar from "react-perfect-scrollbar";
// import "react-perfect-scrollbar/dist/css/styles.css";
// import { Link, useLocation } from "react-router-dom";
// import logo from "../assets/images/logo.png";
// import Header from "./header";
// import { ToastContainer } from "react-toastify";

// const Sidebar = () => {
//     const [isClicked, setIsClicked] = useState(false);
//     const [activeDropdown, setActiveDropdown] = useState("");
//     const location = useLocation();

//     const handleClick = () => {
//         setIsClicked(!isClicked);
//     };

//     const handleDropdownClick = (dropdownId) => {
//         if (activeDropdown === dropdownId) {
//             setActiveDropdown("");
//             localStorage.removeItem("activeDropdown");
//         } else {
//             setActiveDropdown(dropdownId);
//             localStorage.setItem("activeDropdown", dropdownId);
//         }
//     };

//     useEffect(() => {
//         const storedDropdown = localStorage.getItem("activeDropdown");
//         setActiveDropdown(storedDropdown);
//     }, []);

//     useEffect(() => {
//         localStorage.setItem("activeDropdown", activeDropdown);
//     }, [activeDropdown]);

//     const isLinkActive = (path) => {
//         return location.pathname === path ? "active" : "";
//     };

//     const isDropdownOpen = (dropdownId) => {
//         return activeDropdown === dropdownId ? "show" : "";
//     };


//     const handlelogout = () => {
//         // Clear cookies
//         document.cookie.split(";").forEach((c) => {
//             document.cookie = c
//                 .replace(/^ +/, "")
//                 .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
//         });

//         localStorage.clear();
//     };

//     return (
//         <>
//             <ToastContainer />
//             <div
//                 className={`border-end sidenav ${isClicked ? "ml-15" : ""}`}
//                 id="sidebar-wrapper"
//             >
//                 <div className="sidebar-heading border-bottom">
//                     <Link to="/dashboard" className="bg-white " style={{ boxShadow: "0 0 10px #ddd" }}>
//                         <img alt="Alt content" className="img-fluid logo" src={logo} />
//                     </Link>
//                 </div>
//                 <PerfectScrollbar className="sidebar-items">
//                     <ul className="list-unstyled ps-0">
//                         <li className="my-3">

//                             <a className="d-flex align-items-center"
//                             >
//                                 <i class="fa-solid fa-house me-1"></i>
//                                 <Link to="/dashboard" className={`${isLinkActive("/dashboard")} singlepadding`}>
//                                     <span className=""></span> Dashboard
//                                 </Link>
//                             </a>




//                         </li>

//                         <li className="my-3">
//                             <a
//                                 className={`dropdown-toggle ${isLinkActive(
//                                     "/products"
//                                 )}`}
//                                 onClick={() => handleDropdownClick("productsCollapse")}
//                                 role="button"
//                                 aria-expanded={activeDropdown === "productsCollapse"}
//                             >
//                                 <i className="fa-solid fa-box"></i> Products
//                             </a>
//                             <ul
//                                 className={`collapse pl-3 ${isDropdownOpen(
//                                     "productsCollapse"
//                                 )}`}
//                                 id="productsCollapse"
//                             >

//                                 <li>
//                                     <Link
//                                         to="/viewproduct"
//                                         className={isLinkActive("/viewproduct")}
//                                     >
//                                         <span className={`submenulist `}></span> View Product
//                                     </Link>
//                                 </li>
//                                 <li>
//                                     <Link
//                                         to="/product"
//                                         className={isLinkActive("/product")}
//                                     >
//                                         <span className="submenulist"></span> Add Product
//                                     </Link>
//                                 </li>
//                             </ul>
//                         </li>

//                         <li className="my-3">
//                             <a
//                                 className={`dropdown-toggle ${isLinkActive(
//                                     "/products"
//                                 )}`}
//                                 onClick={() => handleDropdownClick("userCollapse")}
//                                 role="button"
//                                 aria-expanded={activeDropdown === "userCollapse"}
//                             >
//                                 <i class="fa-solid fa-user me-1"></i>Users
//                             </a>

//                             <ul className={`collapse pl-3 ${isDropdownOpen(
//                                 "userCollapse"
//                             )}`}
//                                 id="userCollapse">
//                                 <li>
//                                     <Link to="/userlist" className={isLinkActive("/userlist")}>
//                                         <span className="submenulist"></span> User List
//                                     </Link>
//                                 </li>

//                                 <li>
//                                     <Link to="/vendorlist" className={isLinkActive("/vendorlist")}>
//                                         <span className="submenulist"></span> Vendor List
//                                     </Link>
//                                 </li>
//                                 <li>
//                                     <Link to="/influencer" className={isLinkActive("/influencer")}>
//                                         <span className="submenulist"></span> Influencer List
//                                     </Link>
//                                 </li>
//                             </ul>


//                         </li>

//                         <li className="my-3">

//                             <a className="d-flex align-items-center"
//                             >
//                                 <i class="fa-brands fa-first-order me-1"></i>
//                                 <Link to="/orderlist" className={`${isLinkActive("/orderlist")} singlepadding`}>
//                                     <span className=""></span> Invoice List
//                                 </Link>
//                             </a>




//                         </li>


//                         <li className="my-3">

//                             <a className="d-flex align-items-center"
//                             >
//                                 <i class="fa-brands fa-first-order me-1"></i>
//                                 <Link to="/contact" className={`${isLinkActive("/contact")} singlepadding`}>
//                                     <span className=""></span> Contact List
//                                 </Link>
//                             </a>




//                         </li>

//                         <li className="my-3">

//                             <a className="d-flex align-items-center"
//                             >
//                                 <i class="fa-brands fa-first-order me-1"></i>
//                                 <Link to="/ticket" className={`${isLinkActive("/ticket")} singlepadding`}>
//                                     <span className=""></span> Ticket
//                                 </Link>
//                             </a>




//                         </li>
//                         <li className="my-3">




//                             <a
//                                 className={`dropdown-toggle ${isLinkActive(
//                                     "/brandCollapse"
//                                 )}`}
//                                 onClick={() => handleDropdownClick("brandCollapse")}
//                                 role="button"
//                                 aria-expanded={activeDropdown === "brandCollapse"}
//                             >
//                                 <i class="fa-brands fa-bandcamp me-1"></i>Brand
//                             </a>

//                             <ul className={`collapse pl-3 ${isDropdownOpen(
//                                 "brandCollapse"
//                             )}`}
//                                 id="brandCollapse">
//                                 <li>
//                                     <Link tag="a" className={isLinkActive("/brand")} to="/brand">
//                                         <span className="submenulist"></span> Add Brand
//                                     </Link>
//                                     <Link to="/brandlist" className={isLinkActive("/brandlist")}>
//                                         <span className="submenulist"></span> Brand List
//                                     </Link>
//                                 </li>


//                             </ul>


//                         </li>

//                         <li className="my-3">


//                             <a
//                                 className={`dropdown-toggle ${isLinkActive(
//                                     "/categoryCollapse"
//                                 )}`}
//                                 onClick={() => handleDropdownClick("categoryCollapse")}
//                                 role="button"
//                                 aria-expanded={activeDropdown === "categoryCollapse"}
//                             >
//                                 <i class="fa-solid fa-bars"></i> Category
//                             </a>

//                             <ul className={`collapse pl-3 ${isDropdownOpen(
//                                 "categoryCollapse"
//                             )}`}
//                                 id="categoryCollapse">
//                                 <li>
//                                     <Link tag="a" to="/category" className={isLinkActive("/category")}>
//                                         <span className="submenulist"></span> Add Category
//                                     </Link>
//                                     <Link to="/categorylist" className={isLinkActive("/categorylist")}>
//                                         <span className="submenulist"></span> Category List
//                                     </Link>
//                                 </li>
//                             </ul>

//                         </li>

//                         <li className="my-3">



//                             <a
//                                 className={`dropdown-toggle ${isLinkActive(
//                                     "/flowCollapse"
//                                 )}`}
//                                 onClick={() => handleDropdownClick("flowCollapse")}
//                                 role="button"
//                                 aria-expanded={activeDropdown === "flowCollapse"}
//                             >
//                                 <i class="fa-solid fa-house-tsunami"></i> Flow
//                             </a>

//                             <ul className={`collapse pl-3 ${isDropdownOpen(
//                                 "flowCollapse"
//                             )}`}
//                                 id="flowCollapse">
//                                 <li>
//                                     <Link tag="a" to="/flow" className={isLinkActive("/flow")}>
//                                         <span className="submenulist me-1"></span>Add Flow
//                                     </Link>
//                                     <Link to="/flowlist" className={isLinkActive("/flowlist")}>
//                                         <span className="submenulist me-1"> </span>Flow List
//                                     </Link>
//                                 </li>


//                             </ul>


//                         </li>


//                         <li className="my-3">



//                             <a
//                                 className={`dropdown-toggle ${isLinkActive(
//                                     "/sliderCollapse"
//                                 )}`}
//                                 onClick={() => handleDropdownClick("sliderCollapse")}
//                                 role="button"
//                                 aria-expanded={activeDropdown === "sliderCollapse"}
//                             >
//                                 <i class="fa-solid fa-sliders"></i> Slider
//                             </a>

//                             <ul className={`collapse pl-3 ${isDropdownOpen(
//                                 "sliderCollapse"
//                             )}`}
//                                 id="sliderCollapse">
//                                 <li>
//                                     <Link tag="a" to="/slider" className={isLinkActive("/slider")}>
//                                         <span className="submenulist me-1"></span>Add Slider
//                                     </Link>
//                                     <Link to="/sliderlist" className={isLinkActive("/sliderlist")}>
//                                         <span className="submenulist me-1"> </span>Slider List
//                                     </Link>
//                                 </li>
//                             </ul>


//                         </li>


//                         <li className="my-3">



//                             <a
//                                 className={`dropdown-toggle ${isLinkActive(
//                                     "/sectionCollapse"
//                                 )}`}
//                                 onClick={() => handleDropdownClick("sectionCollapse")}
//                                 role="button"
//                                 aria-expanded={activeDropdown === "sectionCollapse"}
//                             >
//                                 <i class="fa-brands fa-nfc-directional"></i> Section
//                             </a>

//                             <ul className={`collapse pl-3 ${isDropdownOpen(
//                                 "sectionCollapse"
//                             )}`}
//                                 id="sectionCollapse">
//                                 <li>
//                                     <Link tag="a" to="/section" className={isLinkActive("/section")}>
//                                         <span className="submenulist me-1"></span>Add Section
//                                     </Link>
//                                     <Link to="/sectionlist" className={isLinkActive("/sectionlist")}>
//                                         <span className="submenulist me-1"> </span>Section List
//                                     </Link>
//                                 </li>
//                             </ul>


//                         </li>


//                         <li className="my-3">



//                             <a
//                                 className={`dropdown-toggle ${isLinkActive(
//                                     "/blogscollapse"
//                                 )}`}
//                                 onClick={() => handleDropdownClick("blogscollapse")}
//                                 role="button"
//                                 aria-expanded={activeDropdown === "blogscollapse"}
//                             >
//                                 <i class="fa-brands fa-nfc-directional"></i> Blogs
//                             </a>

//                             <ul className={`collapse pl-3 ${isDropdownOpen(
//                                 "blogscollapse"
//                             )}`}
//                                 id="blogscollapse">
//                                 <li>
//                                     <Link tag="a" to="/blogs" className={isLinkActive("/blogs")}>
//                                         <span className="submenulist me-1"></span>Add Blogs
//                                     </Link>
//                                     <Link to="/blogslist" className={isLinkActive("/blogslist")}>
//                                         <span className="submenulist me-1"> </span>Blogs List
//                                     </Link>
//                                 </li>
//                             </ul>


//                         </li>






//                         <li className="my-3">

//                             <a className="d-flex align-items-center"
//                             >
//                                 <i class="fa-brands fa-first-order me-1"></i>
//                                 <Link to="/policy" className={`${isLinkActive("/policy")} singlepadding`}>
//                                     <span className=""></span> Policy
//                                 </Link>
//                             </a>




//                         </li>

//                         <li className="my-3">
//                             <a className="d-flex align-items-center"
//                             >
//                                 <i class="fa-brands fa-first-order me-1"></i>
//                                 <Link to="/faqs" className={`${isLinkActive("/faqs")} singlepadding`}>
//                                     <span className=""></span> FAQ
//                                 </Link>
//                             </a>
//                         </li>


//                     </ul>
//                 </PerfectScrollbar>
//                 <div className="dropdown fixed-bottom-dropdown">
//                     <Link tag="a" className="" to="/" onClick={handlelogout}>
//                         <i className="fa-solid fa-right-from-bracket me-2"></i>
//                         <span>Logout</span>
//                     </Link>
//                 </div>
//             </div>
//             <Header
//                 className={`navbar navbar-expand-lg navbar-light bg-light  fixed-top border-bottom ${isClicked ? " left-0" : ""
//                     }`}
//                 handleClick={handleClick}
//             />
//         </>
//     );
// };

// export default Sidebar;



import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import Header from "./header";
import { ToastContainer } from "react-toastify";

// Example menu data array
const menuData = [
    {
        title: "Dashboard",
        icon: "fa-solid fa-house",
        link: "/dashboard",
        type: "single",
        usertype: "Admin"
    },
    {
        title: "Products",
        icon: "fa-solid fa-box",
        link: "/products",
        usertype: "All",
        type: "dropdown",
        dropdownId: "productsCollapse",
        subItems: [
            { title: "View Product", link: "/viewproduct" },
            { title: "Add Product", link: "/product" },
        ],
    },
    {
        title: "Ticket Analyst",
        icon: "fa-solid fa-user",
        link: "/ticketanalyst",
        type: "single",
        usertype: "Admin"
    },
    {
        title: "Users",
        icon: "fa-solid fa-user",
        link: "/users",
        usertype: "Admin",
        type: "dropdown",
        dropdownId: "userCollapse",
        subItems: [
            { title: "User List", link: "/userlist" },
            { title: "Vendor List", link: "/vendorlist" },
            { title: "Influencer List", link: "/influencer" },
        ],
    },
    {
        title: "Invoice List",
        icon: "fa-brands fa-first-order",
        link: "/orderlist",
        type: "single",
        usertype: "Admin",
    },
    {
        title: "Contact List",
        icon: "fa-brands fa-first-order",
        link: "/contact",
        type: "single",
        usertype: "Admin",
    },
    {
        title: "Ticket",
        icon: "fa-brands fa-first-order",
        link: "/ticket",
        usertype: "All",
        type: "single",
    },
    {
        title: "Brand",
        icon: "fa-brands fa-bandcamp",
        link: "/brandCollapse",
        usertype: "Admin",
        type: "dropdown",
        dropdownId: "brandCollapse",
        subItems: [
            { title: "Add Brand", link: "/brand" },
            { title: "Brand List", link: "/brandlist" },
        ],
    },
    {
        title: "Category",
        icon: "fa-solid fa-bars",
        usertype: "Admin",
        link: "/categoryCollapse",
        type: "dropdown",
        dropdownId: "categoryCollapse",
        subItems: [
            { title: "Add Category", link: "/category" },
            { title: "Category List", link: "/categorylist" },
        ],
    },
    {
        title: "Flow",
        icon: "fa-solid fa-house-tsunami",
        link: "/flowCollapse",
        usertype: "Admin",
        type: "dropdown",
        dropdownId: "flowCollapse",
        subItems: [
            { title: "Add Flow", link: "/flow" },
            { title: "Flow List", link: "/flowlist" },
        ],
    },
    {
        title: "Slider",
        usertype: "Admin",
        icon: "fa-solid fa-sliders",
        link: "/sliderCollapse",
        type: "dropdown",
        dropdownId: "sliderCollapse",
        subItems: [
            { title: "Add Slider", link: "/slider" },
            { title: "Slider List", link: "/sliderlist" },
        ],
    },
    {
        title: "Section",
        usertype: "Admin",
        icon: "fa-brands fa-nfc-directional",
        link: "/sectionCollapse",
        type: "dropdown",
        dropdownId: "sectionCollapse",
        subItems: [
            { title: "Add Section", link: "/section" },
            { title: "Section List", link: "/sectionlist" },
        ],
    },
    {
        title: "Country",
        usertype: "Admin",
        icon: "fa-brands fa-first-order",
        link: "/country",
        type: "single",
    },
    {
        title: "HSN",
        usertype: "Admin",
        icon: "fa-brands fa-first-order",
        link: "/hsn",
        type: "single",
    },
    {
        title: "Notification",
        usertype: "Admin",
        icon: "fa-brands fa-first-order",
        link: "/notification",
        type: "single",
    },
    {
        title: "Review",
        usertype: "All",
        icon: "fa-brands fa-first-order",
        link: "/review",
        type: "single",
    },
    {
        title: "Blogs",
        usertype: "Admin",
        icon: "fa-brands fa-nfc-directional",
        link: "/blogscollapse",
        type: "dropdown",
        dropdownId: "blogscollapse",
        subItems: [
            { title: "Add Blogs", link: "/blogs" },
            { title: "Blogs List", link: "/blogslist" },
        ],
    },
    {
        title: "Policy",
        icon: "fa-brands fa-first-order",
        link: "/policy",
        usertype: "Admin",
        type: "single",
    },
    {
        title: "FAQ",
        usertype: "Admin",
        icon: "fa-brands fa-first-order",
        link: "/faqs",
        type: "single",
    },
    {
        title: "Settting",
        usertype: "Admin",
        icon: "fa-brands fa-first-order",
        link: "/settings",
        type: "single",
    },

];

const Sidebar = () => {
    const [isClicked, setIsClicked] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState("");
    const location = useLocation();
    const maintype = localStorage.getItem("usertype")
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const handleClick = () => {
        setIsClicked(!isClicked);
    };


    const handleDropdownClick = (dropdownId) => {
        if (activeDropdown === dropdownId) {
            setActiveDropdown("");
            localStorage.removeItem("activeDropdown");
        } else {
            setActiveDropdown(dropdownId);
            localStorage.setItem("activeDropdown", dropdownId);
        }
    };

    useEffect(() => {
        const storedDropdown = localStorage.getItem("activeDropdown");
        setActiveDropdown(storedDropdown);
    }, []);

    useEffect(() => {
        localStorage.setItem("activeDropdown", activeDropdown);
    }, [activeDropdown]);

    const isLinkActive = (path) => {
        return location.pathname === path ? "active" : "";
    };

    const isDropdownOpen = (dropdownId) => {
        return activeDropdown === dropdownId ? "show" : "";
    };

    const handleLogout = () => {
        // Clear cookies
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });

        localStorage.clear();
    };





    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        // Initialize the state based on the initial window size
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <>
            <ToastContainer />
            <div
                className={`border-end sidenav ${isSmallScreen ? (!isClicked ? "ml-15" : "") : ""} `}
                id="sidebar-wrapper" style={{ zIndex: "999" }}
            >
                <div className="sidebar-heading border-bottom">
                    <Link to="/dashboard" className="bg-white " style={{ boxShadow: "0 0 10px #ddd" }}>
                        <img alt="Alt content" className="img-fluid logo" src={logo} />
                    </Link>
                </div>
                <PerfectScrollbar className="sidebar-items">
                    <ul className="list-unstyled ps-0">
                        {menuData.map((item, index) => (
                            (item.usertype == maintype || item.usertype == "All") && <li key={index} className="my-3">
                                {item.type === "single" ? (
                                    <a className="d-flex align-items-center">
                                        <i className={`${item.icon} me-1`}></i>
                                        <Link to={item.link} className={`${isLinkActive(item.link)} singlepadding`}>
                                            {item.title}
                                        </Link>
                                    </a>
                                ) : (
                                    <>
                                        <a
                                            className={`dropdown-toggle ${isLinkActive(item.link)}`}
                                            onClick={() => handleDropdownClick(item.dropdownId)}
                                            role="button"
                                            aria-expanded={activeDropdown === item.dropdownId}
                                        >
                                            <i className={`${item.icon} me-1`}></i> {item.title}
                                        </a>
                                        <ul
                                            className={`collapse pl-3 ${isDropdownOpen(item.dropdownId)}`}
                                            id={item.dropdownId}
                                        >
                                            {item.subItems.map((subItem, subIndex) => (
                                                <li key={subIndex}>
                                                    <Link to={subItem.link} className={isLinkActive(subItem.link)}>
                                                        <span className="submenulist"></span> {subItem.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </PerfectScrollbar>

                {!isSmallScreen ? <div className="dropdown fixed-bottom-dropdown">
                    <Link tag="a" className="" to="/" onClick={handleLogout}>
                        <i className="fa-solid fa-right-from-bracket me-2"></i>
                        <span>Logout</span>

                    </Link>
                </div> : <Link tag="a" className="" to="/" onClick={handleLogout}>
                    <i className="fa-solid fa-right-from-bracket me-2"></i>
                    <span>Logout</span>

                </Link>}
            </div>
            <Header
                className={`navbar navbar-expand-lg navbar-light bg-light fixed-top border-bottom ${isSmallScreen ? (!isClicked ? "left-0" : "") : ""} `}
                handleClick={handleClick}
            />
        </>
    );
};

export default Sidebar;
