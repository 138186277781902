import React, { useEffect, useState } from 'react'
import adminLayout from '../hoc/adminLayout'
import { Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import axios from 'axios'
import { BaseUrl } from '../utils/Token'
import Loader from '../common/Loader'
import { DataGrid } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import { toast } from 'react-toastify'

function Policy() {
    const token = localStorage.getItem("token")
    const [title, settitle] = useState("")
    const [shortdes, setshortdes] = useState("")
    const [name, setname] = useState("")
    const [desc, setdesc] = useState("")
    const [category, setcategory] = useState("")
    const [open, setopen] = useState(false)
    const [data, setdata] = useState([])
    const [editid, seteditid] = useState('')




    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setdesc(data);
    };

    const handleget = () => {
        axios.get(`${BaseUrl}/pages`, { headers }).then((res) => {
            console.log(res.data.data)
            setdata(res.data.data)
        })
    }

    useEffect(() => {
        handleget()
    }, [])
    const getRowId = (row) => row._id;
    const handleEditClick = (item) => {
        setname(item.name)
        setdesc(item.description)
        setshortdes(item.short_description)
        setcategory(item.category)
        settitle(item.title)
        seteditid(item._id)
    };

    const handleClear = () => {
        setname("")
        setdesc("")
        setshortdes("")
        setcategory("")
        settitle("")
        seteditid("")
    }

    const columns = [
        // { field: "_id", headerName: "ID", flex:1},
        { field: "name", headerName: "Name", flex: 1 },
        { field: "category", headerName: "Category", flex: 1 },


        {
            field: "edit",
            headerName: "Edit",

            width: 300,
            renderCell: (params) => {



                return (
                    <Link to={``}>
                        <IconButton
                            style={{ color: "black" }}
                            aria-label="edit"
                            onClick={() => handleEditClick(params.row)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Link>
                );
            },
        },

    ];

    const handleFormSubmit = (e) => {
        e.preventDefault()
        setopen(true)
        let requestdata = {
            description: desc,
            title: title,
            short_description: shortdes,
            name: name,
            category: category
        }
        if (editid) {
            requestdata._id = editid
            axios.put(`${BaseUrl}/pages`, requestdata, { headers }).then((res) => {
                console.log(res.data.status)
                if (res.data.status == "OK") {
                    toast.success("Data Updated Successfully")
                    setopen(false)
                    handleget()
                    handleClear()
                }
            })
        } else {
            axios.post(`${BaseUrl}/pages`, requestdata, { headers }).then((res) => {
                if (res.data.status == "OK") {
                    toast.success("Data Submit Successfully")
                    handleClear()
                    handleget()
                    setopen(false)
                }
            })
        }
    }
    return (
        <>
            <Loader open={open} />
            <section>
                <Container>
                    <Row className='gy-3'>
                        <Col md="12">
                            <div className="bg-white box">
                                <h4 className="px-3 py-3">  Policy</h4>
                                <div className="">
                                    <Form onSubmit={handleFormSubmit}>
                                        <Row className="p-3 productform gy-3">
                                            <Col md="4">
                                                <FormGroup>
                                                    <label htmlFor="" className="fw-bold">
                                                        Category
                                                    </label>

                                                    <select className='form-control form-select' value={category} onChange={(e) => setcategory(e.target.value)}>
                                                        <option value="">--Select--</option>
                                                        <option value="terms">Terms</option>
                                                        <option value="privacy">Privacy</option>
                                                        <option value="refund">Refund</option>
                                                        <option value="aboutus">About us</option>
                                                        <option value="itpolicy">IT Policy</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <label htmlFor="" className="fw-bold">
                                                        Title
                                                    </label>
                                                    <input type="text" className='form-control' value={title} onChange={(e) => settitle(e.target.value)} />


                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup>
                                                    <label htmlFor="" className="fw-bold">
                                                        Short Description
                                                    </label>
                                                    <input type="text" className='form-control' value={shortdes} onChange={(e) => setshortdes(e.target.value)} />


                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <label htmlFor="" className="fw-bold">
                                                        Name
                                                    </label>
                                                    <input type="text" className='form-control' value={name} onChange={(e) => setname(e.target.value)} />


                                                </FormGroup>
                                            </Col>

                                            <Col md="12" className="mb-3">
                                                <FormGroup>
                                                    <label htmlFor="" className="fw-bold">
                                                        Description
                                                    </label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={desc}
                                                        onChange={handleEditorChange}
                                                        className="custom-ckeditor"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="12">
                                                <div className="">
                                                    <button className="btn bg-primary text-white">
                                                        {editid ? "Update" : "Add"} Policy
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="bg-white  box">
                                <h4 className="px-3 py-3">Policy List</h4>
                                <div className="">
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                        initialState={{
                                            ...data.initialState,
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[5, 10, 25]}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default adminLayout(Policy)
