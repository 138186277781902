import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { BaseUrl, token } from "../../utils/Token";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import ImageComponent from "../../components/ImageComponent";

function EditBrand() {
  const [brand, setBrand] = useState([]);
  const [name, setName] = useState("");

  const [flow, setFlow] = useState("");
  const [file, setFile] = useState(null);
  const [flowApi, setFlowApi] = useState([]);
  const [open, setOpen] = useState(false);
  const [trademarkfile, settrademarkfile] = useState("")
  const [is_trademark, setis_trademark] = useState("")
  // const 

  const { id } = useParams();
  const naivgate = useNavigate();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };



  const [Idenititycert, setIdenitityCert] = useState(null)

  const [editidentity, seteditidentity] = useState(false)


  const [enlargedSrc, setEnlargedSrc] = useState(null);

  const closeEnlargedView = () => {
    setEnlargedSrc(null);
  };

  const getBrandbyId = () => {
    axios
      .get(`${BaseUrl}/single_brand/${id}`, { headers })
      .then((res) => {
        setBrand(res.data.data[0]);
        console.log(brand);

        setFlow(res.data.data[0].flow._id);
        setName(res.data.data[0].name);
        setis_trademark(res.data.data[0].is_trademark)

        if (res.data.data[0].business_document !== "") {
          seteditidentity(true)
        }

        setFile(res.data.data[0].image);
        console.log(file);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const FlowgetApi = () => {
    axios
      .get(`${BaseUrl}/flows`, { headers })
      .then((res) => {
        setFlowApi(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBrandbyId();
    FlowgetApi();
  }, [id]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    const formData = new FormData();
    formData.append("name", name);

    formData.append("flow", flow);

    formData.append("business_documents", trademarkfile)
    formData.append("is_trademark", is_trademark)

    formData.append("_id", id);
    formData.append("file", file); // Add the file to the form data

    axios
      .put(`${BaseUrl}/brands`, formData, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.status == "OK") {
          toast.success("Data Updated Successfully");
          setOpen(false);
          naivgate("/brandlist");
        }
        // You can perform any additional actions after the update is successful
      })
      .catch((error) => {
        console.log("Error updating data:", error);
        toast.error("Data Not Updated Submited ");
        setOpen(false);
        naivgate("/brandlist");

        // Handle any errors that occurred during the update
      });
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile); // Store the uploaded file directly
  };


  // const handletrademark = (e) => {
  //   let selectedfile = e.target.files[0]
  //   settrademarkfile(selectedfile)
  // }


  const getFileUrl = () => {
    if (editidentity) {
      return `${BaseUrl}/${Idenititycert}`;
    } else if (Idenititycert instanceof Blob) {
      return URL.createObjectURL(new Blob([Idenititycert]));
    } else if (typeof Idenititycert === 'string') {
      return Idenititycert;
    }
    return '';
  };


  const fileUrl = getFileUrl();
  const isPdf = fileUrl && fileUrl.endsWith('.pdf');
  const handlePdfClick = () => {
    window.open(fileUrl);
  };

  const handleImageClick = (src) => {
    setEnlargedSrc(src);
  };

  // const handleidenitity = (event) => {
  //   const uploadaadharFront = event.target.files[0];
  //   // Store the uploaded file directly
  //   setIdenitityCert(uploadaadharFront);
  //   seteditidentity(false)
  // };

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white box">
                <h4 className="px-3 py-3">Update Brand</h4>
                <div className="">
                  <Form onSubmit={handleFormSubmit}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            required
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Flow
                          </label>
                          <select
                            className="form-select"
                            name="flow"
                            aria-label="Default select example"
                            value={flow}
                            onChange={(e) => setFlow(e.target.value)}
                          >
                            <option selected>Selected a Flow</option>
                            {flowApi?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Image
                          </label>
                          <input
                            type="file"
                            name="image"
                            // onChange={(e) => setFile(e.target.value)}
                            onChange={handleFileUpload}
                            className="form-control"
                          />
                        </FormGroup>
                        <div className="mt-3">
                          {file && (
                            <p>
                              Uploaded file:{" "}
                              <span className="fw-bold">{file.name}</span>
                            </p>
                          )}
                        </div>
                      </Col>

                      <Col md='6'>
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">You Have Trademark ? </label>
                          <select name="" value={is_trademark} onChange={(e) => setis_trademark(e.target.value)} className="form-control form-select" id="">
                            <option value="">--Select--</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </FormGroup>
                      </Col>
                      {/* <Col md="6">
                        <FormGroup className="d-flex w-100 gap-3 align-items-center">
                          {Idenititycert && (
                            isPdf ? (
                              <div onClick={handlePdfClick} className="mt-4" style={{ cursor: 'pointer' }}>
                                <i class="fa-solid fa-file fs-1 text-danger"></i>
                              </div>
                            ) : (
                              <ImageComponent
                                type="file"
                                enlargedSrc={enlargedSrc}
                                closeEnlargedView={closeEnlargedView}
                                handleImageClick={handleImageClick}
                                src={URL.createObjectURL(new Blob([Idenititycert]))}
                              />
                            )
                          )}
                          <div className="w-100">
                            <label htmlFor="" className="fw-bold">Identity Certificate</label>
                            <input
                              type="file"
                              name="file"
                              className="form-control w-100"
                              onChange={handleidenitity}
                            />
                          </div>
                        </FormGroup>
                      </Col> */}

                      <Col md="12">
                        <div className="">
                          <button className="btn bg-primary text-white">
                            Update Brand
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Loader open={open} />
    </>
  );
}

export default adminLayout(EditBrand);
