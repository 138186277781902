import React, { useEffect, useState } from "react";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import axios from "axios";
import { BaseUrl, token } from "../../utils/Token";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import { Country, State, City } from 'country-state-city';
import { toast } from "react-toastify";
import ImageComponent from "../../components/ImageComponent";

function UserDetailComp(props) {
  const location = useLocation()
  const [username, setUsername] = useState("");
  const [editfile, seteditfile] = useState(false)
  const [email, setemail] = useState("")
  const [businesstype, setbusinesstype] = useState("")
  const [stdcode, setstdcode] = useState("")
  const [firstname, setfirstname] = useState("")
  const [lastname, setlastname] = useState("")
  const [countrydata, setcountrydata] = useState([])
  const [address, setaddress] = useState("")
  const [selfdelivery, setselfdelivery] = useState("")
  const [charges, setcharges] = useState("")
  const [maintainfee, setmaintainfee] = useState("")


  const [registeraddress, setregisteraddress] = useState("")

  const [image, setImage] = useState("");
  const [type, setType] = useState("");

  const [editfront, seteditfront] = useState(false)
  const [editback, seteditback] = useState(false)
  const [editpanimage, seteditpanimage] = useState(false)

  const [InfluencerData, setInfluencerData] = useState(false);
  const [data, setData] = useState([]);
  const [bankVerified, setBankVerified] = useState("");
  const [pan, setPan] = useState("");
  const [panverified, setPanVerified] = useState("");
  const [adharVerified, setAdharVerified] = useState("");
  const [Gst, setGst] = useState("");
  const [gstVerified, setgstVerified] = useState("");
  const [SocialLinks, setSocialLinks] = useState("");
  const [Adhar, setAdhar] = useState("");
  const [file, setFile] = useState(null);
  const [aadharfront, setAadharFront] = useState(null);
  const [aadharback, setAadharBack] = useState(null);
  const [panimg, setPanimg] = useState(null);
  const [rows, setRows] = useState([{ type: "", value: "" }]);
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [phone, setphone] = useState("");
  const [ein, setein] = useState("");
  const [aoc, setaoc] = useState("");

  const [isactive, setisactive] = useState(false)

  const [country, setcountry] = useState([])


  const [selectcountry, setselectcountry] = useState("")

  useEffect(() => {
    setcountry(Country.getAllCountries())
  }, [])




  const [open, setOpen] = useState(false);

  const [enlargedSrc, setEnlargedSrc] = useState(null);
  const [bussinessemail, setbusinessemail] = useState("")
  const [Idenititycert, setIdenitityCert] = useState(null)
  const [editidentity, seteditidentity] = useState(false)

  const [maindocument, setmaindocument] = useState(null)
  const [editmaindoc, seteditmaindoc] = useState(false)
  const [alternatemain, setalternatemain] = useState(null)
  const [editalertnate, seteditalertnate] = useState(false)


  const vendorpage = location.pathname == "/addvendor"



  const handleImageClick = (src) => {
    setEnlargedSrc(src);
  };

  const handlesameaddrss = (e) => {
    let value = e.target.checked
    if (value) {
      setregisteraddress(address)
    } else {
      setregisteraddress("")

    }
  }

  const closeEnlargedView = () => {
    setEnlargedSrc(null);
  };





  const { id } = useParams();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        //   "https://twinscart.com:8000/fetch_users_admin?type=User",
        `${BaseUrl}/fetch_users_admin?id=${id}`,
        {
          headers,
        }
      );
      setData(response.data.data);
      console.log(response.data.data);
      const dataUser = response.data.data;

      setUsername(dataUser.username);
      setstdcode(dataUser?.phone?.slice(0, -10))
      setphone(dataUser.phone?.slice(-10))
      setImage(dataUser.image);
      setType(response.data.data.type);
      setBankVerified(dataUser.is_bank_verified);
      setAdhar(dataUser.aadhaar);

      setPan(dataUser.pan);
      setGst(dataUser.gst);
      setemail(dataUser.email)
      setAdharVerified(dataUser.is_aadhaar_verified);
      setselectcountry(dataUser?.business_country)
      setPanVerified(dataUser.is_pan_verified);
      setPan(dataUser.pan);
      setGst(dataUser.gst);
      setgstVerified(dataUser.is_gst_verified);
      setein(dataUser?.ein)
      setaoc(dataUser?.aob)




      setFile(dataUser.image);
      setIdenitityCert(dataUser?.identity_doc)
      if (dataUser.image != "") {
        seteditfile(true)
      }
      if (dataUser.identity_doc != "") {
        seteditidentity(true)
      }
      if (dataUser.business_documents != "") {
        seteditmaindoc(true)
      }
      if (dataUser.business_alternative_document != "") {
        seteditalertnate(true)
      }
      if (dataUser.identity_doc != "") {
        seteditidentity(true)
      }
      if (dataUser.aadhaar_front != "") {
        seteditfront(true)
      }
      if (dataUser.aadhaar_back != "") {
        seteditback(true)
      }
      if (dataUser.pan_image != "") {
        seteditpanimage(true)
      }
      setAccountNumber(dataUser.bank_details.acn);
      setBankName(dataUser.bank_details.bn);
      setIfscCode(dataUser.bank_details.ifsc);
      setAccountHolderName(dataUser.bank_details.ahn);
      setAadharFront(dataUser.aadhaar_front);
      setAadharBack(dataUser.aadhaar_back);
      setPanimg(dataUser.pan_image);
      setselfdelivery(dataUser.self_delivery)
      setmaintainfee(dataUser?.monthly_fees_to_admin)
      setcharges(dataUser?.delivery_charge)
      //bussiness 
      setbusinesstype(dataUser?.business_type)
      setisactive(dataUser?.is_active)
      setfirstname(dataUser?.business_owner_fn)
      setlastname(dataUser?.business_owner_ln)
      setbusinessemail(dataUser?.business_owner_email)

      setaddress(dataUser?.business_address)
      setregisteraddress(dataUser?.business_registered_address)
      setmaindocument(dataUser?.business_documents)
      setalternatemain(dataUser?.business_alternative_document)

    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  useEffect(() => {
    if (!vendorpage) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (type == "Influencer" || type == "Vendor" || vendorpage) {
      setInfluencerData(true);
    } else {
      setInfluencerData(false);
    }
  }, [type]);

  const navigate = useNavigate()

  const handleUserForm = async (e) => {
    e.preventDefault();
    setOpen(true);

    const formData = new FormData();
    if (!vendorpage) {
      formData.append("_id", id);
    }
    formData.append("username", stdcode + phone);
    formData.append("type", vendorpage ? "Vendor" : type);
    formData.append("file", file);
    formData.append("is_bank_verified", bankVerified);
    formData.append("image_1", aadharfront);
    formData.append("image_2", aadharback);
    formData.append("aadhaar", Adhar);
    formData.append("is_aadhaar_verified", adharVerified);
    formData.append("is_pan_verified", panverified);
    formData.append("pan", pan);
    formData.append("self_delivery", selfdelivery);
    formData.append("delivery_charge", charges);
    formData.append("monthly_fees_to_admin", maintainfee);



    formData.append("image_3", panimg);
    formData.append("gst", Gst);
    formData.append("business_country", selectcountry)
    formData.append("is_gst_verified", gstVerified);
    formData.append("phone", stdcode + phone);
    formData.append("bank_details[acn]", accountNumber);
    formData.append("bank_details[bn]", bankName);
    formData.append("bank_details[ifsc]", ifscCode);
    formData.append("bank_details[ahn]", accountHolderName);
    formData.append("referal_code", `${stdcode + phone}`)

    //businessdetail

    formData.append("is_active", isactive);
    formData.append("business_type", businesstype)
    formData.append("business_owner_fn", firstname)
    formData.append("business_owner_ln", lastname)
    formData.append("business_owner_email", bussinessemail)
    formData.append("business_address", address)
    formData.append("business_registered_address", registeraddress)


    formData.append("identity_doc", Idenititycert);
    formData.append("ein", ein)

    formData.append("aob", aoc)

    formData.append("business_documents", maindocument)
    formData.append("business_alternative_document", alternatemain)
    // formData.append("business_country", selectcountry)

    rows.forEach((item, index) => {
      formData.append(`social_links[${index}][type]`, item.type);
      formData.append(`social_links[${index}][value]`, item.value);
    });

    if (vendorpage) {
      axios
        .post(`${BaseUrl}/store_user`, formData, {
          headers: headers,
        })
        .then((response) => {
          // Handle successful response
          console.log(response.data);
          if (response.data.status == "OK") {
            toast.success("data submit Successfully");
            setOpen(false);
            navigate('/vendorlist')
          }
        })
        .catch((error) => {
          // Handle error
          console.error(error);
          toast.error("data not Updated");
          setOpen(false);
        });
    } else {
      axios
        .put(`${BaseUrl}/update_user_admin`, formData, {
          headers: headers,
        })
        .then((response) => {
          // Handle successful response
          console.log(response.data);
          if (response.data.status == "OK") {
            toast.success("data Updated Successfully");
            setOpen(false);
          }
        })
        .catch((error) => {
          // Handle error
          console.error(error);
          toast.error("data not Updated");
          setOpen(false);
        });
    }
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile); // Store the uploaded file directly
    seteditfile(false)
  };

  const handlefrontAdhar = (event) => {
    const uploadaadharFront = event.target.files[0];
    // Store the uploaded file directly
    setAadharFront(uploadaadharFront);
    seteditfront(true)
  };

  const handleidenitity = (event) => {
    const uploadaadharFront = event.target.files[0];
    // Store the uploaded file directly
    setIdenitityCert(uploadaadharFront);
    seteditidentity(false)
  };

  const handleBackAdhar = (event) => {
    const uploadaadharBack = event.target.files[0];
    setAadharBack(uploadaadharBack);
    seteditback(false)
  };
  const handlePan = (event) => {
    const uploadPan = event.target.files[0];
    setPanimg(uploadPan);
    seteditpanimage(true)
  };

  const addRow = (e) => {
    e.preventDefault();
    setRows([...rows, { type: "", value: "" }]);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };


  const handlemaindoc = (event) => {
    const uploadaadharFront = event.target.files[0];
    // Store the uploaded file directly
    setmaindocument(uploadaadharFront);
    seteditmaindoc(false)
  };

  const handlealternatechange = (event) => {
    const uploadaadharFront = event.target.files[0];
    // Store the uploaded file directly
    setalternatemain(uploadaadharFront);
    seteditalertnate(false)
  }


  const getFileUrl = () => {
    if (editidentity) {
      return `${BaseUrl}/${Idenititycert}`;
    } else if (Idenititycert instanceof Blob) {
      return URL.createObjectURL(new Blob([Idenititycert]));
    } else if (typeof Idenititycert === 'string') {
      return Idenititycert;
    }
    return '';
  };

  const getmaindocument = () => {

    if (editmaindoc) {
      return `${BaseUrl}/${maindocument}`;
    } else if (maindocument instanceof Blob) {
      return URL.createObjectURL(new Blob([maindocument]));
    } else if (typeof maindocument === 'string') {
      return maindocument;
    }
    return '';
  };


  const getalternate = () => {
    if (editalertnate) {
      return `${BaseUrl}/${alternatemain}`;
    } else if (alternatemain instanceof Blob) {
      return URL.createObjectURL(new Blob([alternatemain]));
    } else if (typeof alternatemain === 'string') {
      return alternatemain;
    }
    return '';
  };

  const fileUrl = getFileUrl();
  const isPdf = fileUrl && fileUrl.endsWith('.pdf');

  const filemainUrl = getmaindocument();
  const ismaindocuPdf = filemainUrl && filemainUrl.endsWith('.pdf');

  const filealternate = getalternate();
  const isalternatepdf = filealternate && filealternate.endsWith('.pdf');

  const handlePdfClick = () => {
    window.open(fileUrl);
  };

  const handlemainPdfClick = () => {
    window.open(filemainUrl);
  };

  const handlealternatepdf = () => {
    window.open(filealternate);
  };



  const fetchcountries = () => {
    axios.get(`${BaseUrl}/fetchAllCountries`).then((res) => {
      console.log(res.data)
      setcountrydata(res.data.data)
    })
  }

  useEffect(() => {
    fetchcountries()
  }, [])

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  addProduct">
                <h4 className="px-3 py-3">Details</h4>
                <div className="">
                  <Form onSubmit={handleUserForm}>
                    <Row className="p-3 productform">
                      {/* <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Username
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={username}
                            name="name"
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </FormGroup>
                      </Col> */}
                      <Col md="6">
                        <FormGroup className="d-flex w-100 gap-3">

                          {file && <ImageComponent type={file} enlargedSrc={enlargedSrc} closeEnlargedView={closeEnlargedView} handleImageClick={handleImageClick} src={editfile ? `${BaseUrl}/${file} ` : URL.createObjectURL(new Blob([file]))} />}
                          <div className="w-100">
                            <label htmlFor="" className="fw-bold">
                              {" "}
                              Image
                            </label>
                            <input
                              type="file"
                              name="file"
                              className="form-control w-100"
                              onChange={handleFileUpload}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      {(vendorpage || type == "Vendor") && <div className="col-md-6">
                        <div className="form-group mb-4">
                          <label htmlFor="" className="fw-bold">
                            Select Country <span className="text-danger">*</span>
                          </label>
                          <select name="" value={selectcountry} onChange={(e) => setselectcountry(e.target.value)} id="" className="form-control form-select ">
                            <option value="">--Select--</option>
                            {countrydata.map((item) => {
                              return (
                                <option value={item.name}>{item.name}</option>

                              )
                            })}

                          </select>
                        </div>
                      </div>}
                      {(!vendorpage && type != "Vendor") && <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Type
                          </label>
                          <select
                            class="form-select"
                            name="type"
                            aria-label="Default select example"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option selected>Open this select menu</option>
                            <option value="User">User</option>
                            <option value="Influencer">Influencer</option>
                          </select>
                        </FormGroup>
                      </Col>}

                      <div className="col-md-6">
                        <div className="form-group mb-4">
                          <label htmlFor="" className="fw-bold">
                            Mobile Number <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex align-items-center">
                            <select name="" style={{ width: "180px" }} value={stdcode} onChange={(e) => setstdcode(e.target.value)} className="form-control  form-select" id="">
                              <option value="" >Select  Code</option>
                              {countrydata?.map((item) => {
                                return (
                                  <>
                                    <option value={item.std_code}>{item.std_code} ({item.name})</option>
                                  </>
                                )
                              })}
                            </select>
                            <input
                              type="text"
                              required
                              className="form-control w-100 "
                              readOnly={!vendorpage ? true : false}
                              disabled={!vendorpage ? true : false}
                              minLength={10}
                              maxLength={10}
                              value={phone}
                              placeholder="Phone"
                              onChange={(e) => setphone(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      {email && <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Email
                          </label>
                          <input
                            type="email"
                            readOnly={!vendorpage ? true : false}
                            disabled={!vendorpage ? true : false}
                            className="form-control"
                            value={email}
                            name="email"
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>}
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Business Owner Email
                          </label>
                          <input
                            type="Business Owner Email"
                            readOnly={!vendorpage ? true : false}
                            disabled={!vendorpage ? true : false}
                            className="form-control"
                            value={bussinessemail}
                            name="bussinessemail"
                            onChange={(e) => setbusinessemail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      {InfluencerData ? (
                        <>
                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                {" "}
                                Is Bank Verified
                              </label>

                              <select
                                class="form-select"
                                aria-label="Default select example"
                                name="is_bank_verified"
                                value={bankVerified}
                                onChange={(e) =>
                                  setBankVerified(e.target.value)
                                }
                              >
                                <option selected>Open this select menu</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </FormGroup>
                          </Col>
                          {/* <Col md="6">
                            <FormGroup className="d-flex w-100 gap-3">


                              {aadharfront && <ImageComponent type={aadharfront} enlargedSrc={enlargedSrc} closeEnlargedView={closeEnlargedView} handleImageClick={handleImageClick} src={editfront ? `${BaseUrl}/${aadharfront}` : URL.createObjectURL(new Blob([aadharfront]))} />}

                              <div className="w-100">
                                <label htmlFor="" className="fw-bold">
                                  {" "}
                                  Aadhaar Front
                                </label>
                                <input
                                  type="file"
                                  name="image_1"
                                  className="form-control"
                                  onChange={handlefrontAdhar}
                                />
                              </div>
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <FormGroup className="d-flex w-100 gap-3">
                                

                                {aadharback && <ImageComponent type={aadharback} enlargedSrc={enlargedSrc} closeEnlargedView={closeEnlargedView} handleImageClick={handleImageClick} src={editback ? `${BaseUrl}/${aadharback}` : URL.createObjectURL(new Blob([aadharback]))} />}

                                <div className="w-100">
                                  <label htmlFor="" className="fw-bold">
                                    {" "}
                                    Aadhaar Back
                                  </label>
                                  <input
                                    type="file"
                                    name="image_2"
                                    className="form-control"
                                    onChange={handleBackAdhar}
                                  />
                                </div>
                              </FormGroup>
                            </FormGroup>
                          </Col> */}
                          {/* <Col md="6">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                {" "}
                                Aadhar
                              </label>
                              <input
                                type="text"
                                value={Adhar}
                                onChange={(e) => setAdhar(e.target.value)}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col> */}



                          <Col md="6">
                            <FormGroup className="d-flex w-100 gap-3 align-items-center">
                              {Idenititycert && (
                                isPdf ? (
                                  <div onClick={handlePdfClick} className="mt-4" style={{ cursor: 'pointer' }}>
                                    <i class="fa-solid fa-file fs-1 text-danger"></i>
                                  </div>
                                ) : (
                                  <ImageComponent
                                    type="file"
                                    enlargedSrc={enlargedSrc}
                                    closeEnlargedView={closeEnlargedView}
                                    handleImageClick={handleImageClick}
                                    src={editidentity ? `${BaseUrl}/${Idenititycert}` : URL.createObjectURL(new Blob([Idenititycert]))}
                                  />
                                )
                              )}
                              <div className="w-100">
                                <label htmlFor="" className="fw-bold">Identity Certificate</label>
                                <input
                                  type="file"
                                  name="file"
                                  className="form-control w-100"
                                  onChange={handleidenitity}
                                />
                              </div>
                            </FormGroup>
                          </Col>


                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                {" "}
                                Is Identity Certificate Verified
                              </label>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                name="is_aadhaar_verified"
                                value={adharVerified}
                                onChange={(e) =>
                                  setAdharVerified(e.target.value)
                                }
                              >
                                <option selected>Open this select menu</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                {" "}
                                Is Bussiness Document Verified
                              </label>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                name="is_aadhaar_verified"
                                value={panverified}
                                onChange={(e) => setPanVerified(e.target.value)}
                              >
                                <option selected>Open this select menu</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </FormGroup>
                          </Col>
                          {/* <Col md="6">
                            <FormGroup>
                              <label htmlFor="" className="fw-bold">
                                {" "}
                                Pan
                              </label>
                              <input
                                type="text"
                                value={pan}
                                onChange={(e) => setPan(e.target.value)}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col> */}


                          <Col md="6">
                            <FormGroup className="d-flex w-100 gap-3 align-items-center">
                              {maindocument && (
                                ismaindocuPdf ? (
                                  <div onClick={handlemainPdfClick} className="mt-4" style={{ cursor: 'pointer' }}>
                                    <i class="fa-solid fa-file fs-1 text-danger"></i>
                                  </div>
                                ) : (
                                  <ImageComponent
                                    type="file"
                                    enlargedSrc={enlargedSrc}
                                    closeEnlargedView={closeEnlargedView}
                                    handleImageClick={handleImageClick}
                                    src={editmaindoc ? `${BaseUrl}/${maindocument}` : URL.createObjectURL(new Blob([maindocument]))}
                                  />
                                )
                              )}
                              <div className="w-100">
                                <label htmlFor="" className="fw-bold">Business Document <span className="text-danger">*</span></label>
                                <input
                                  type="file"
                                  name="file"
                                  className="form-control w-100"
                                  onChange={handlemaindoc}
                                />
                              </div>
                            </FormGroup>
                          </Col>


                          <Col md="6">
                            <FormGroup className="d-flex w-100 gap-3 align-items-center">
                              {alternatemain && (
                                isalternatepdf ? (
                                  <div onClick={handlealternatepdf} className="mt-4" style={{ cursor: 'pointer' }}>
                                    <i class="fa-solid fa-file fs-1 text-danger"></i>
                                  </div>
                                ) : (
                                  <ImageComponent
                                    type="file"
                                    enlargedSrc={enlargedSrc}
                                    closeEnlargedView={closeEnlargedView}
                                    handleImageClick={handleImageClick}
                                    src={editalertnate ? `${BaseUrl}/${alternatemain}` : URL.createObjectURL(new Blob([alternatemain]))}
                                  />
                                )
                              )}
                              <div className="w-100">
                                <label htmlFor="" className="fw-bold">Business Document <span className="text-danger">(* Alternate)</span></label>
                                <input
                                  type="file"
                                  name="file"
                                  className="form-control w-100"
                                  onChange={handlealternatechange}
                                />
                              </div>
                            </FormGroup>
                          </Col>

                          {/* <Col md="6">
                            <FormGroup className="d-flex w-100 gap-3">


                              {panimg && <ImageComponent type={panimg} enlargedSrc={enlargedSrc} closeEnlargedView={closeEnlargedView} handleImageClick={handleImageClick} src={editpanimage ? `${BaseUrl}/${panimg}` : URL.createObjectURL(new Blob([panimg]))} />}

                              <div className="w-100">
                                <label htmlFor="" className="fw-bold">
                                  {" "}
                                  Pan Image
                                </label>
                                <input
                                  type="file"
                                  name="image_3"
                                  className="form-control"
                                  onChange={handlePan}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          {vendorpage && <>
                            <Col md="6">
                              <FormGroup>
                                <label htmlFor="" className="fw-bold">
                                  {" "}
                                  Gst
                                </label>
                                <input
                                  type="text"
                                  value={Gst}
                                  onChange={(e) => setGst(e.target.value)}
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <label htmlFor="" className="fw-bold">
                                  {" "}
                                  Gst Verified
                                </label>

                                <select
                                  class="form-select"
                                  aria-label="Default select example"
                                  name="is_aadhaar_verified"
                                  value={gstVerified}
                                  onChange={(e) => setgstVerified(e.target.value)}
                                >
                                  <option selected>Open this select menu</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </FormGroup>
                            </Col></>} */}
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label htmlFor="" className="fw-bold">Employer Identification Number (EIN)</label>
                              <input type="text" className="form-control" value={ein} onChange={(e) => setein(e.target.value)} />
                            </div>

                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label htmlFor="" className="fw-bold">AOC</label>
                              <input type="text" className="form-control" value={aoc} onChange={(e) => setaoc(e.target.value)} />
                            </div>

                          </div>

                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label htmlFor="" className="fw-bold">Is Vendor Active</label>
                              <select name="" className="form-control form-select" id="" value={isactive} onChange={(e) => setisactive(e.target.value)}>
                                <option value="">--Select--</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>

                              </select>
                            </div>

                          </div>


                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label htmlFor="" className="fw-bold">Self Delivery</label>
                              <select name="" className="form-control form-select" id="" value={selfdelivery} onChange={(e) => setselfdelivery(e.target.value)}>
                                <option value="">--Select--</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>

                              </select>
                            </div>

                          </div>


                          {(selfdelivery == "true" || selfdelivery) && <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label htmlFor="" className="fw-bold">Charges</label>
                              <input type="text" className="form-control" value={charges} onChange={(e) => setcharges(e.target.value)} />
                            </div>

                          </div>}
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label htmlFor="" className="fw-bold">Maintenance Charges</label>
                              <input type="text" className="form-control" value={maintainfee} onChange={(e) => setmaintainfee(e.target.value)} />
                            </div>

                          </div>
                          <div className="col-md-12">
                            <h3 className="mb-3 detailHeading">Business Detail</h3>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-4">
                              <label htmlFor="" className="fw-bold">Business Type</label>
                              <select name="" value={businesstype} onChange={(e) => setbusinesstype(e.target.value)} className="form-control form-select " id="">
                                <option value="">--Select--</option>
                                <option value="B2C">Business-to-Consumer (B2C)</option>
                                <option value="B2B">Business-to-Business (B2B)</option>
                                <option value="B2G">Business-to-Government (B2G)</option>
                                <option value="C2C">Consumer-to-Consumer (C2C)</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-4">
                              <label htmlFor="" className="fw-bold">First Name</label>
                              <input type="text" value={firstname} onChange={(e) => setfirstname(e.target.value)} className="form-control " />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-4">
                              <label htmlFor="" className="fw-bold">Last Name</label>
                              <input type="text" value={lastname} onChange={(e) => setlastname(e.target.value)} className="form-control " />
                            </div>
                          </div>
                          {/* <div className="col-md-4">
                            <div className="form-group mb-4">
                              <label htmlFor="" className="fw-bold">Email</label>
                              <input type="text" value={bussinessemail} onChange={(e) => setbusinessemail(e.target.value)} className="form-control " />
                            </div>
                          </div> */}
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group mb-4">
                                <label htmlFor="" className="fw-bold">Address</label>
                                <textarea name="" value={address} onChange={(e) => setaddress(e.target.value)} id="" className="form-control"></textarea>
                                <div className="mt-2">
                                  <input type="checkbox" onChange={(e) => handlesameaddrss(e)} className="pe-2" id="sameaddress" />
                                  <label htmlFor="sameaddress" style={{ fontSize: "12px" }} className="fw-bold">If your Address and Registered Address are the same, then click the checkbox.</label>

                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group mb-4">
                                <label htmlFor="" className="fw-bold">Registered Address</label>
                                <textarea name="" id="" value={registeraddress} onChange={(e) => setregisteraddress(e.target.value)} className="form-control"></textarea>
                              </div>
                            </div>
                          </div>
                          <Col md="12">
                            <h3 className="detailHeading">Bank Details</h3>
                            <Row className="bankDetail">
                              <Col md="4">
                                <FormGroup>
                                  <label htmlFor="acn" className="fw-bold">
                                    Account Number:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="acn"
                                    name="acn"
                                    value={accountNumber}
                                    onChange={(e) =>
                                      setAccountNumber(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="4">
                                <FormGroup>
                                  <label htmlFor="bn" className="fw-bold">
                                    Bank Name:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="bn"
                                    name="bn"
                                    value={bankName}
                                    onChange={(e) =>
                                      setBankName(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="4">
                                <FormGroup>
                                  <label htmlFor="ifsc" className="fw-bold">
                                    Routing Number:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="ifsc"
                                    name="ifsc"
                                    value={ifscCode}
                                    onChange={(e) =>
                                      setIfscCode(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="4">
                                <FormGroup>
                                  <label htmlFor="ahn" className="fw-bold">
                                    Account Holder Name:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="ahn"
                                    name="ahn"
                                    value={accountHolderName}
                                    onChange={(e) =>
                                      setAccountHolderName(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>

                          {/* <Col md="12">
                            <div className="">
                              <div className="w-100 d-flex align-items-center justify-content-between mb-3">
                                <h3 className="detailHeading mb-0">
                                  Social Links
                                </h3>
                                <button
                                  onClick={addRow}
                                  className="btn bg-primary text-white"
                                >
                                  Add Row
                                </button>
                              </div>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>Value</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {rows.map((row, index) => (
                                    <tr key={index}>
                                      <td>
                                        <FormGroup>
                                          <input
                                            type="text"
                                            name={`social_link[${index}].type`}
                                            placeholder="Type"
                                            className="form-control"
                                            value={row.type}
                                            onChange={(e) =>
                                              handleChange(
                                                index,
                                                "type",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </FormGroup>
                                      </td>
                                      <td>
                                        <FormGroup>
                                          <textarea
                                            type="text"
                                            name={`more_info[${index}].value`}
                                            className="form-control"
                                            placeholder="Value"
                                            value={row.value}
                                            onChange={(e) =>
                                              handleChange(
                                                index,
                                                "value",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </FormGroup>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Col> */}
                        </>
                      ) : null}

                      <Col md="12">
                        <div className="">
                          <button class="btn">Submit</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Loader open={open} />
      </section>
    </>
  );
}

export default adminLayout(UserDetailComp);
