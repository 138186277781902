import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BaseUrl } from '../../utils/Token'
import adminLayout from '../../hoc/adminLayout'
import { DataGrid } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import Loader from '../../common/Loader'
import { toast } from 'react-toastify'

function AddHsn() {
    const [name, setName] = useState("")
    const [open, setopen] = useState(false)
    const [code, setcode] = useState("")
    const [data, setdata] = useState("")
    const [commission, setcommission] = useState("")
    const [editid, seteditid] = useState("")
    const [active, setactive] = useState("")

    let token = localStorage.getItem("token");

    const headers = {
        Authorization: `Bearer ${token}`
    };
    const handlesubmit = (e) => {
        e.preventDefault()
        setopen(true)
        let requestdata = {
            name,
            code,
            commission,
            active,
        }
        if (editid) {
            requestdata._id = editid
            axios.put(`${BaseUrl}/hsns`, requestdata, { headers }).then((res) => {
                console.log(res)
                if (res.data.status == "OK") {

                    toast.success("HSN Submit Successfully")
                    setName("")
                    handleget()
                    setcode("")
                    setcommission("")
                }
            }).finally((err) => {
                setopen(false)
            })
        } else {
            axios.post(`${BaseUrl}/hsns`, requestdata, { headers }).then((res) => {
                console.log(res)
                if (res.data.status == "OK") {

                    toast.success("HSN Submit Successfully")
                    setName("")
                    handleget()
                    setcode("")
                    setcommission("")
                }
            }).finally((err) => {
                setopen(false)
            })
        }
    }


    const handleget = () => {
        setopen(true)
        axios.get(`${BaseUrl}/hsns`, { headers }).then((res) => {
            if (res.data.status == "OK") {
                setdata(res.data.data)
                setopen(false)
            }
        })
    }

    useEffect(() => {
        handleget()
    }, [])

    const getRowId = (row) => row._id

    const columns = [
        // { field: "_id", headerName: "ID", width: 400 },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "code", headerName: "Code", flex: 1 },
        { field: "commission", headerName: "Commission", flex: 1 },
        { field: "active", headerName: "Status", flex: 1 },




        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => {
                const id = params.row;
                return (

                    <IconButton
                        style={{ color: "black" }}
                        aria-label="edit"
                        onClick={() => handleEditClick(id)}
                    >
                        <EditIcon />
                    </IconButton>

                );
            },
        },
    ];


    const handleEditClick = (main) => {
        setName(main.name)
        seteditid(main._id)
        setactive(main.active)
        setcode(main.code)
        setcommission(main.commission)
    }

    return (
        <>
            <Loader open={open} />
            <section>
                <div className="container">
                    <div className="row ">
                        <div className="col-md-12 text-center mb-3">
                            <h3>Add Hsn</h3>
                        </div>
                        <div className="col-md-12">
                            <form onSubmit={(e) => handlesubmit(e)}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label htmlFor="">Name <span className='text-danger'>*</span> </label>
                                        <input type="text" className='form-control' value={name} onChange={(e) => setName(e.target.value)} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="">Code  <span className='text-danger'>*</span></label>
                                        <input type="text" className='form-control' value={code} onChange={(e) => setcode(e.target.value)} required />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="">Commission  <span className='text-danger'>*</span></label>
                                        <input type="text" required className='form-control' value={commission} onChange={(e) => setcommission(e.target.value)} />
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <label htmlFor="">Status  <span className='text-danger'>*</span></label>
                                        <select className='form-control form-select ' value={active} onChange={(e) => setactive(e.target.value)}>
                                            <option value="">Select Status</option>
                                            <option value="true">Active</option>
                                            <option value="false">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="addProduct mt-4">
                                        <button className='btn'>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-12">
                            <div className="mt-4">
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    getRowId={getRowId}
                                    initialState={{
                                        ...data.initialState,
                                        pagination: { paginationModel: { pageSize: 10 } },
                                    }}
                                    pageSizeOptions={[5, 10, 25]}
                                // checkboxSelection
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default adminLayout(AddHsn)
