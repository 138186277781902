import React, { useEffect, useState } from 'react'
import adminLayout from '../hoc/adminLayout'
import { Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import axios from 'axios'
import { BaseUrl } from '../utils/Token'
import Loader from '../common/Loader'
import { DataGrid } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import { toast } from 'react-toastify'

function Review() {
    const token = localStorage.getItem("token")
    const [title, settitle] = useState("")
    const [shortdes, setshortdes] = useState("")
    const [name, setname] = useState("")
    const [desc, setdesc] = useState("")
    const [category, setcategory] = useState("")
    const [productid, setproductid] = useState("")
    const [open, setopen] = useState(false)
    const [data, setdata] = useState([])
    const [product, setproduct] = useState([])

    const [editid, seteditid] = useState('')




    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const handleproduct = () => {
        setopen(true)
        axios.get(`${BaseUrl}/product`, { headers }).then((res) => {
            console.log(res.data.status == "OK")
            if (res.data.status == "OK") {
                setproduct(res.data.data)
                setopen(false)
            }
        })
    }



    const handleget = () => {
        setopen(true)
        axios.get(`${BaseUrl}/product_reviews?id=${productid}`, { headers }).then((res) => {
            console.log(res.data.status == "OK")
            if (res.data.status == "OK") {
                setdata(res.data.data)
                setopen(false)
            }
        })
    }

    useEffect(() => {
        handleget()

    }, [productid])

    useEffect(() => {
        handleproduct()
    }, [])
    const getRowId = (row) => row._id;




    const columns = [
        // { field: "_id", headerName: "ID", flex:1},
        { field: "review", headerName: "Review", flex: 1 },
        { field: "star", headerName: "Star", flex: 1 },
        {
            field: "product", headerName: "Product Name", flex: 1, renderCell: (params) => {
                return (
                    <>
                        <h6>{params.row?.product?.short_name}</h6>
                    </>
                )
            }
        },



    ];


    return (
        <>
            <Loader open={open} />
            <section>
                <Container>
                    <Row className='gy-3'>


                        <Col md="12">

                            <div className="bg-white  box">
                                <div className='d-flex align-items-center border-bottom justify-content-between'>
                                    <h4 className="px-3 py-3 border-0">Review  List</h4>
                                    <div className='d-flex gap-2'>
                                        <select onChange={(e) => setproductid(e.target.value)} className='form-control form-select' style={{ width: "max-content" }}>
                                            <option value="">Select Product</option>
                                            {product.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item._id}>{item.short_name}</option>
                                                    </>
                                                )
                                            })}


                                        </select>
                                        <button className='btn bg-primary text-white rounded-3' onClick={(e) => setproductid("")}>Clear All Filter</button>
                                    </div>
                                </div>
                                <div className="">
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                        initialState={{
                                            ...data.initialState,
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[5, 10, 25]}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default adminLayout(Review)
